var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticClass:"custom__modal--large",attrs:{"id":"modal-order-note","hide-header":"","hide-footer":"","ok-only":"","ok-title":"Accept","centered":"","size":"lg","title":"Large Modal"}},[_c('div',{staticClass:"section"},[_c('header',{staticClass:"sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"3"}},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.directPage}},[_c('feather-icon',{staticClass:"text-dark font-weight-bolder",attrs:{"size":"24","icon":"ArrowLeftIcon"}}),_c('span',{staticClass:"text-dark text-darken-4 fw-bold-600 size14 ml-1"},[_vm._v("Kembali")])],1)]),_c('b-col',{staticClass:"d-flex justify-content-center align-items-center",attrs:{"cols":"6"}},[_c('h1',{staticClass:"text-dark h6 font-weight-bolder mb-0 text-center"},[_vm._v(" Informasi Note Penjualan ")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"3"}},[_c('b-button',{staticClass:"px-3",attrs:{"disabled":!_vm.formPayload.name || !_vm.formPayload.note},on:{"click":function($event){_vm.editId == null ? _vm.createItem() : _vm.updateItem()}}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Simpan ")],1)],1)],1)],1),_c('b-container',{staticClass:"mt-5 pt-5"},[_c('b-row',{staticClass:"justify-content-center h-100 bg-white pb-5"},[_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12","md":"12"}},[_c('label',{attrs:{"for":"v-nama"}},[_vm._v(" Nama "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-group',[_c('b-form-input',{staticClass:"custom__input",attrs:{"id":"v-nama","type":"text","placeholder":"Nama Note"},model:{value:(_vm.formPayload.name),callback:function ($$v) {_vm.$set(_vm.formPayload, "name", $$v)},expression:"formPayload.name"}}),(
                    _vm.messagesChild &&
                    _vm.messagesChild.name &&
                    Array.isArray(_vm.messagesChild.name) &&
                    _vm.messagesChild.name.length > 0
                  )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.messagesChild.name.join(", ")))]):_vm._e()],1)],1),_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12","md":"12"}},[_c('label',{attrs:{"for":"v-nama"}},[_vm._v(" Note "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-group',[_c('b-form-textarea',{staticClass:"custom__input",attrs:{"id":"v-nama","placeholder":"Note Penjualan","rows":"5","max-rows":"6"},model:{value:(_vm.formPayload.note),callback:function ($$v) {_vm.$set(_vm.formPayload, "note", $$v)},expression:"formPayload.note"}}),(
                    _vm.messagesChild &&
                    _vm.messagesChild.name &&
                    Array.isArray(_vm.messagesChild.name) &&
                    _vm.messagesChild.name.length > 0
                  )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.messagesChild.name.join(", ")))]):_vm._e()],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }