<template>
  <div class="master-toko">
    <div class="section">
      <header-page>
        <span
          class="size14 fw-bold-700"
          style="color: #0b5fff; margin-right: 5px"
          >Master</span
        >
        <span class="text-dark text-darken-5" style="margin-right: 5px">></span>
        <span class="text-dark fw-bold-400 size14">Order Note</span>
      </header-page>

      <!-- <div
        class="transaction__filter--container py-2 pl-0 pr-2 justify-content-between"
        style="height: 82px; background-color: #f6f7fa"
      >
        <b-button-group>
          <b-button
            v-for="(item, index) in listFilter"
            :key="`${item}-${index}`"
            class="filter--button"
            :class="{ active: item == filter }"
            @click="
              (filter = item),
                (comp = item == 'TableOrderNote')
            "
          >
            {{ item }}
          </b-button>
        </b-button-group>
      </div> -->

      <keep-alive>
        <component :is="comp" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { BContainer, BButtonGroup, BButton } from "bootstrap-vue";
import TableOrderNote from "@/components/OrderNote/TableOrderNote.vue";

import HeaderPage from "@/components/HeaderPage.vue";

export default {
  title() {
    return "Master Billing";
  },
  components: {
    BContainer,
    BButtonGroup,
    BButton,
    HeaderPage,
    TableOrderNote,
  },
  data() {
    return {
      comp: "TableOrderNote",
    };
  },
  mounted() {
  },
  //   watch: {
  //     filter: {
  //       handler: _.debounce(function () {
  //         this.getListSubscription();
  //       }, 300),
  //       deep: true,
  //     },
  //   },

  methods: {},
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

.master-toko {
  button.bg-white {
    &:focus {
      background-color: transparent !important;
    }
  }
}
</style>
